import { render, staticRenderFns } from "./price-list-home.vue?vue&type=template&id=34f3a7d2&"
import script from "./price-list-home.vue?vue&type=script&lang=js&"
export * from "./price-list-home.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports